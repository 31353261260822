@import "variables";
[class="ant-btn-primary"] {
  background-color: $primary;
  border-color: $primary;
}
[class="ant-btn-secondary"] {
  background-color: $secondary;
  border-color: $secondary;
}
[class="ant-btn--warning"] {
  background-color: $warning;
  border-color: $warning;
}
[class="ant-btn-danger"] {
  background-color: $danger;
  border-color: $danger;
}
.anticon {
  vertical-align: middle;
}
//skeleton
.ant-skeleton-content .ant-skeleton-paragraph {
  margin-top: 5px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 10px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 10px;
  border-radius: 50px;
}
//layout
.ant-layout-header {
  background: #ffffff;
}

//Message
.ant-message {
  z-index: 99999999999;
}

//forms
.ant-form-item {
  margin-bottom: 12px;
}
.ant-form-item-label {
  font-family: "font-medium";
}
.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
  padding-top: 2px;
}
.ant-upload-list-item {
  padding: 5px;
}
.ant-row.ant-form-item.field-horizontal {
  display: flex;
  flex-direction: revert;
  align-items: center;
  .ant-form-item-label {
    padding-bottom: 0;
  }
  .ant-form-item-control {
    text-align: right;
  }
}
.ant-form-item-label > label.ant-form-item-required::before {
  display: inline-block;
  margin-right: 0;
  color: #ff4d4f;
  line-height: 1;
  content: "";
}
.ant-form-item-label > label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  //background-color: #fff;
  //opacity: 0.6;
}
//Input
.ant-input-group-addon {
  border-radius: 5px 0 0 5px;
}
// .ant-picker-input > input {
//   padding: 8.5px 14px;
// }
ant-input-affix-wrapper,
.ant-picker,
// .ant-input {
//   border-radius: 5px;
//   height: 45px;
// }
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  // border-color: #42299a;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.context-menu .ant-dropdown-menu-item {
  svg {
    margin-right: 8px;
    font-size: 16px;
  }
}

//Button
.ant-btn {
  svg {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  & > svg + span {
    margin-left: 8px;
  }

  &.mk-btn {
    &.ant-btn-primary.ant-btn-block {
      color: white;
      border: $success;
      border-radius: 6px;
      height: 38px;
      background-color: $success;
    }
  }
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  font-family: font-bold;
}
table {
  .ant-table-thead > tr > th {
    background-color: #f7f7f7;
    font-family: font-medium;
    font-size: 0.75rem;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    height: 50px;
  }
  .ant-table-tbody > tr > td {
    height: 50px;
  }
}
.virtual-table table {
  width: auto !important;
}
.page-menu {
  background: transparent;
  .ant-menu {
    background: transparent;
    padding: 14px;
    .ant-menu-item {
      color: $dark;
      border-radius: 5px;
      &.ant-menu-item-selected {
        color: $light;
        font-family: font-medium;
        background: $primary;
      }
    }
  }
}
.rounded-search {
  padding: 0.8rem 1rem 0.8rem 1rem;
  width: 100%;
  .ant-input-search {
    height: 32px;
    color: #b2c2d1;
    font-size: 0.8571rem;
    //border-radius: 50px;
    //background-color: #edf2f7;
    //border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    input {
      background: transparent;
      margin-right: 10px;
    }
  }
  .ant-input-search-icon {
    display: none;
  }
}

.txn-type-radio-btn {
  $border-colors: #e0ecff;
  border: solid 1px $border-colors;
  border-radius: 6px;
  .ant-radio-button-wrapper {
    box-shadow: none;
    :before {
      background-color: transparent !important;
    }
    border: $border-colors;
    border-radius: 6px;
    &.ant-radio-button-wrapper-checked {
      background-color: $border-colors;
      border-radius: initial;
      color: $primary;
      &:before {
        background-color: transparent !important;
      }
      &:focus-within {
        box-shadow: none;
      }
    }
  }
}
.due-message.ant-alert {
  font-size: 0.89rem;
}
